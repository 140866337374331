<template>
  <div class="admin-reset-password">
    <AdminHeader />
    <main>
      <b-container class="mt-5">
        <b-row>
          <b-col>
            <b-alert
              :show="!!message"
              :variant="response.success ? `success` : `danger`"
              >{{ response.message }}</b-alert
            >
            <ValidationObserver ref="observer" v-slot="{ invalid }">
              <b-form @submit.prevent="onChangePassword" v-if="!!!message">
                <b-row v-if="form.action === 1">
                  <b-col md="6">
                    <b-form-group
                      label="Login Username:"
                      label-for="input-username"
                    >
                      <ValidationProvider
                        name="Username"
                        rules="required"
                        v-slot="validationContext"
                      >
                        <b-form-input
                          id="input-username"
                          type="text"
                          v-model="form.email"
                          :state="getValidationState(validationContext)"
                          placeholder="Enter login email"
                        ></b-form-input>
                        <b-form-invalid-feedback>{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="form.action === 2">
                  <b-col md="6">
                    <b-form-group label="Password:" label-for="input-password">
                      <ValidationProvider
                        name="Password"
                        rules="required|strong_password"
                        v-slot="validationContext"
                      >
                        <b-form-input
                          id="input-password"
                          type="password"
                          v-model="form.password"
                          :state="getValidationState(validationContext)"
                          placeholder="Enter password"
                        ></b-form-input>
                        <b-form-invalid-feedback>{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="form.action === 2">
                  <b-col md="6">
                    <b-form-group
                      label="Confirm Password:"
                      label-for="input-confirm-password"
                    >
                      <ValidationProvider
                        name="Confirm Password"
                        rules="required|confirmed:Password"
                        v-slot="validationContext"
                      >
                        <b-form-input
                          id="input-confirm-password"
                          type="password"
                          v-model="form.confirmPassword"
                          :state="getValidationState(validationContext)"
                          placeholder="Re-enter password"
                        ></b-form-input>
                        <b-form-invalid-feedback>{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="mt-4">
                  <input
                    type="hidden"
                    name="email"
                    v-model="form.email"
                    v-if="form.action === 2"
                  />
                  <b-col>
                    <b-button
                      type="submit"
                      variant="primary"
                      :disabled="invalid || processing"
                      ><b-spinner
                        small
                        style="position: relative; top: -2px; margin-right: 5px"
                        v-if="processing"
                      ></b-spinner
                      >{{ btnText }}</b-button
                    >
                  </b-col>
                </b-row>
              </b-form>
              <b-row>
                <b-col md="6">
                  <b-button
                    variant="primary"
                    @click="$router.push(`/login`)"
                    v-if="!!message"
                  >
                    Go Login</b-button
                  >
                </b-col>
              </b-row>
            </ValidationObserver>
          </b-col>
        </b-row>
      </b-container>
    </main>
  </div>
</template>

<script>
import AdminHeader from "@/components/admin/Header.vue";
import * as AccountService from "@/services/AccountService";

export default {
  name: "reset-password",
  components: {
    AdminHeader
  },
  data: () => ({
    form: {
      token: null,
      email: null,
      password: null,
      confirmPassword: null,
      action: 1
    },
    btnText: "Request Password Reset",
    processing: false,
    message: null,
    response: {
      message: null,
      success: true
    }
  }),
  computed: {
    loggedIn() {
      return this.$store.getters["auth/isAuthenticated"];
    }
  },
  created() {
    if (this.loggedIn) {
      let userId = this.$store.state.auth.user.id;
      this.$router.push(`/admin/${userId}/dashboard`);
    }

    if (this.$route.query.token && this.$route.query.email) {
      this.form.email = this.$route.query.email;
      this.form.token = this.$route.query.token;
      this.form.action = 2;
      this.btnText = "Reset Password";
    } else {
      this.form.action = 1;
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    resetForm() {
      this.form = {
        token: null,
        email: null,
        password: null,
        confirmPassword: null,
        action: 1
      };
    },
    onChangePassword() {
      this.isSubmitting = true;
      this.processing = true;

      this.$refs.observer.validate().then(valid => {
        if (valid) {
          AccountService.ResetPasswordAsync(this.form)
            .then(data => {
              this.processing = false;
              this.message = data;
              this.response.message = data.message;
              this.response.success = true;
              this.resetForm();
            })
            .catch(e => {
              this.processing = false;
              this.message = e;
              this.response.message =
                "Token is expired or has been already used";
              this.response.success = false;
            });
        } else {
          this.processing = false;
          this.message = "Please fill up required fields!";
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/admin/_base.scss";
</style>

import Repository from "@/repositories/RepositoryFactory";

const AccountRepository = Repository.get("AccountRepository");

export const ResetPasswordAsync = async data => {
  try {
    const response = await AccountRepository.ResetPassword(data);
    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};
